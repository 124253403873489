import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonToast,
} from "@ionic/react";
import { close, filter } from "ionicons/icons";
import React, { useState } from "react";
import { AppHeader } from "../components/AppHeader";
import { setMilestoneActive } from "../helpers/Context";
import { fetchMilestones, cropText } from "../helpers/fetchEntryData";
import { fetchFilterLabels } from "../helpers/fetchAppData";
import { FilterLabels, Milestone } from "../helpers/JCTypes";

const Touren: React.FC = () => {
  const [milestones, setMilestones] = useState<Milestone[]>([]);

  const [filtersvisible, setFiltersvisible] = useState(false);

  const [filterActive, setFilterActive] = useState(false);

  const [katfilter, setkatfilter] = useState<{ [key: string]: string[] }>({});
  const [filterLabels, setFilterLabels] = useState<FilterLabels>();
  const [toastVisible, setToastVisible] = useState(false);
  const [toastText, setToastText] = useState('');

  //Refs für fix vier Filter-Kategorien, ggf. Anzahl erhöhen
  const selectRefs = [
    React.createRef<HTMLIonSelectElement>(),
    React.createRef<HTMLIonSelectElement>(),
    React.createRef<HTMLIonSelectElement>(),
    React.createRef<HTMLIonSelectElement>()
  ];

  const updateFilter = () => {
    let hasFilterVals = false;
    const tmpFilter: { [key: string]: any } = {};
    selectRefs.forEach(ref => {
      if (ref.current) {
        const classList = ref.current.classList;
        if (classList.item(0) != null) {
          const className = classList.item(0);
          if (className != null) {
            const cat = className.substr("cat-".length);
            tmpFilter[cat] = ref.current.value;
            hasFilterVals = hasFilterVals || ref.current.value > 0;
          }
        }
      }
    });
    setFilterActive(hasFilterVals);
    setkatfilter(tmpFilter);
  };

  const doRefresh = (
    event: CustomEvent<RefresherEventDetail> | null,
    forceReload = false
  ) => {
    fetchMilestones((milestones: Milestone[]) => {
      if (milestones.length > 0) {
        setMilestones(milestones);
        if (event) event.detail.complete();
      }
    }, forceReload);
    fetchFilterLabels((result: FilterLabels) => {
      setFilterLabels(result);
    }, forceReload);
  };

  useIonViewWillEnter(() => {
    resetFilter();
  });

  useIonViewDidEnter(() => {
    setMilestoneActive(false);
    doRefresh(null);
  });

  const passFilter = (milestone: Milestone) => {
    for (const cat in katfilter) {
      let filterPassed = false;
      if (katfilter[cat] && katfilter[cat].length) {
        if (milestone.categories[cat] && milestone.categories[cat].length) {
          milestone.categories[cat].forEach(function (poiVal) {
            if (katfilter[cat].indexOf(poiVal.uid.toString()) > -1) {
              filterPassed = true;
            }
          });
        }
        if (!filterPassed) return "";
      }
    }

    const showUrheber = (event: React.MouseEvent, info: string) => {
      event.preventDefault();
      event.stopPropagation();
      setToastText(info);
      setToastVisible(true)
    }

    return (
      <IonCol key={milestone.uid} size="12" sizeMd="6" sizeLg="4">
        <IonItem routerLink={"/liste/milestone/" + milestone.uid}>
          <IonRow>
            <IonCol size="4">
              <img src={milestone.prvImage.src} alt={""} style={{ objectFit: 'contain' }} onClick={(event) => showUrheber(event, milestone.prvImage.description)} />
              <img src={milestone.partnerImage.src} alt={""} style={{ objectFit: 'contain' }}/>
            </IonCol>
            <IonCol size="8">
              <IonLabel>
                <h3>{milestone.title}</h3>
                <div>{cropText(milestone.description)}</div>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonItem>
      </IonCol>
    );
  };

  const resetFilter = () => {
    setFiltersvisible(false);
    selectRefs.forEach(function (ref) {
      if (ref.current) {
        ref.current.value = [];
      }
    });
    updateFilter();
  };

  const createFilter = () => {
    const filter: { [key: string]: any } = {};

    //vorhandene Kategorien und Werte aus Daten ermitteln
    milestones.forEach(milestone => {
      if (milestone.categories) {
        for (const key in milestone.categories) {
          if (key === "places") continue;
          if (!(key in filter)) {
            filter[key] = {};
          }
          for (const index in milestone.categories[key]) {
            const filterVal = milestone.categories[key][index];
            if (!(filterVal.uid in filter[key])) {
              filter[key][filterVal.uid] = filterVal.title;
            }
          }
        }
      }
    });

    //Markup zusammenbauen
    const effCategories = Object.keys(filter);

    //manuell umsortieren, damit gewünschte reihenfolge passt
    const categories : string[] = [];
    if (effCategories.includes('themes')) categories.push('themes');
    if (effCategories.includes('time')) categories.push('time');
    if (effCategories.includes('districts')) categories.push('districts');

    return milestones.length ? (
      <div className="jc-nomargin">
        {categories.length ? (
          categories.map((cat, index) => {
            return (
              <IonItem key={cat}>
                <IonLabel>
                  {filterLabels && cat in filterLabels
                    ? filterLabels[cat]
                    : cat}
                </IonLabel>
                <IonSelect
                  class={"cat-" + cat}
                  ref={selectRefs[index]}
                  okText="Filter setzen"
                  cancelText="Abbrechen"
                  multiple
                  onIonChange={updateFilter}
                  value={katfilter[cat] || null}
                >
                  {Object.keys(filter[cat]).map(val => {
                    return (
                      <IonSelectOption key={val} value={val}>
                        {filter[cat][val]}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            );
          })
        ) : (
          <IonItem>
            Für die Touren auf dieser Seite wurden keine Kategorien vergeben
          </IonItem>
        )}

        <IonRow className="list-header">
          <IonCol>
            <IonButton
              fill="outline"
              color="light"
              expand="full"
              className="ion-text-right text-muted"
              onClick={() => {
                resetFilter();
              }}
            >
              Filter zurücksetzen
            </IonButton>
            <IonButton
                fill="outline"
                color="light"
                expand="full"
                className="ion-text-right text-muted"
                onClick={() => {
                  setFiltersvisible(false);
                }}
                style={{ marginTop: 8 }}
            >
              Filter anwenden
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    ) : (
      <></>
    );
  };

  const createContent = () => {
    return (
      <>
        {milestones.length ? (
          <IonGrid>
            <IonRow className="jc-nopadding">
              {milestones.map(milestone => {
                return passFilter(milestone);
              })}
            </IonRow>
          </IonGrid>
        ) : null}
      </>
    );
  };

  return (
    <IonPage>
      <AppHeader />
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={e => {
            doRefresh(e, true);
          }}
        >
          <IonRefresherContent refreshingSpinner="dots" />
        </IonRefresher>
        <IonRow className="list-header">
          <IonCol>
            <h3>Touren</h3>{" "}
            {milestones.length === 0 ? (
              <IonSpinner name="dots" color="medium" />
            ) : null}
          </IonCol>
          {milestones.length ? <IonCol>
            <p className="ion-text-right">
              <IonButton
                fill={filterActive ? "outline" : "clear"}
                className="text-muted"
                onClick={() => {
                  setFiltersvisible(true);
                }}
              >
                Filter <IonIcon icon={filter} />
              </IonButton>
            </p>
          </IonCol> : ''}
        </IonRow>
        {createContent()}
        <IonToast
            isOpen={toastVisible}
            onDidDismiss={() => setToastVisible(false) }
            message={toastText}
            duration={2000}
        />
      </IonContent>
      <IonModal
        isOpen={filtersvisible}
        onDidDismiss={() => setFiltersvisible(false)}
        cssClass="filter-overlay"
      >
        <IonToolbar>
          <IonTitle>Filter</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setFiltersvisible(false);
              }}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent>{createFilter()}</IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Touren;
