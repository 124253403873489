import {IonButton, IonCol, IonList, IonRow, isPlatform} from '@ionic/react';
import React, {useEffect} from 'react';
import {Marker, Popup, Tooltip} from 'react-leaflet';
import { Position } from '../helpers/JCTypes';
import {DivIcon} from "leaflet";

export interface MarkerProps {
  title: string;
  uid: number;
  teaser: string;
  position: Position;
  highlight: string;
  highlightcount: number;
  isCulture: boolean;
  hasText: boolean;
  hasAudio: boolean;
  hasVideo: boolean;
}

export default function POIMarker ({ title, uid, teaser, position , highlight, highlightcount, isCulture, hasText, hasAudio, hasVideo}: MarkerProps) {
  const handleClick = () => {
    let destination = position.lat + ',' + position.lng;
    if (isPlatform('ios')) {
      window.open('maps://?q=' + destination, '_system');
    } else if (isPlatform('desktop')) {
      window.open('https://www.google.com/maps/dir//' + destination, '_blank');
    } else {
      let label = encodeURI('My Label');
      window.open('geo:0,0?q=' + destination + '(' + label + ')', '_system');
    }
  };

  let markerRef = React.createRef<Marker>();

  useEffect(() => {
    if(markerRef.current) {
      if (highlight === 'all') {
        markerRef.current.leafletElement.setOpacity(1.0);
      } else if (highlight === 'muted') {
        markerRef.current.leafletElement.setOpacity(0.5);
      } else if (highlight === 'single') {
        markerRef.current.leafletElement.setOpacity(1.0);
        window.setTimeout(()=>{ if(markerRef.current) markerRef.current.leafletElement.openPopup();}, 1000);
      } else if (highlight === 'milestone') {
        markerRef.current.leafletElement.setOpacity(1.0);
      }
    }
  }, [highlight, markerRef]);

  const typeClass = `${isCulture ? ' has-kultur' : ''}
    ${hasText ? ' has-text' : ''}
    ${hasAudio ? ' has-audio' : ''}
    ${hasVideo ? ' has-video' : ''}`;

  const icon = new DivIcon({
    html: '<svg data-name="Ebene 1" viewBox="0 0 122.63 145.94" xmlns="http://www.w3.org/2000/svg">\n' +
        '<defs>\n' +
        '<style>\n' +
        '.ac871974-b65b-4048-a502-744ac472e6f0{fill:#2eb1bb;}\n' +
        '.kultur-icon, .audio-icon, .video-icon, .text-icon{fill:#25989d;}\n' +
        '.e31b7d5f-618e-428b-93a0-493281022031{fill:none;stroke:#fff;stroke-width:2px;}\n' +
        '.b6595aa7-5a0a-42af-977e-380dc8b00341{fill:#2cb1bb;}</style>\n' +
        '</defs>\n' +
        '<circle class="ac871974-b65b-4048-a502-744ac472e6f0" cx="61.31" cy="61.32" r="60.31" data-name="circle54"/>\n' +
        '<path class="b68c4224-8106-47ac-af41-2ee1085aa01d" transform="translate(0)" d="M61.31,2A59.31,59.31,0,1,1,2,61.32H2A59.38,59.38,0,0,1,61.31,2m0-2a61.31,61.31,0,1,0,61.32,61.31A61.31,61.31,0,0,0,61.31,0Z" data-name="path56"/>\n' +
        '<line class="e31b7d5f-618e-428b-93a0-493281022031" x1="60.3" x2="60.3" y1="122.6" data-name="line58"/>\n' +
        '<line class="e31b7d5f-618e-428b-93a0-493281022031" x2="122.63" y1="61.32" y2="61.32" data-name="line60"/>\n' +
        '<path class="kultur-icon" transform="translate(0)" d="M50.07,48V35.34h3.22L37.19,21.05,21.09,35.34h3.22V48a1.5,1.5,0,0,0,1.42,1.58.6.6,0,0,0,.19,0H34V38.52h6.42v11.1H48.5a1.5,1.5,0,0,0,1.57-1.44Z"/>\n' +
        '<path class="text-icon" transform="translate(0)" d="m 76.169922,69.339844 c -2.100306,-7e-6 -3.805034,1.700482 -3.810547,3.800781 v 22.25 c 0.01098,2.098018 1.712507,3.793364 3.810547,3.798828 h 16.689453 c 2.100299,-0.0055 3.800788,-1.708288 3.800781,-3.808594 v -16.68164 c 0.0031,-0.198896 -0.07163,-0.380824 -0.177734,-0.544922 0.29872,0.592588 0.01729,1.476562 -0.873047,1.476562 -9.25071,0.143343 -9.914714,0.996622 -9.339844,-9.351562 0,-0.448198 0.300712,-0.812392 0.707031,-0.939453 z m 11.132812,0.0039 0.0078,0.01563 c 0.265987,0 0.504449,0.107981 0.683594,0.277344 -0.183377,-0.184428 -0.431794,-0.288172 -0.691407,-0.292969 z m 0.726563,0.326172 c 0.172238,0.179644 0.28125,0.420927 0.28125,0.689453 v 7.271484 h 7.298828 c 0.279513,0 0.486116,0.101299 0.648437,0.240235 z M 79,79.060547 h 2.810547 c 1.333333,0 1.333333,2 0,2 H 79 c -0.552285,0 -1,-0.447715 -1,-1 0,-0.552285 0.447715,-1 1,-1 z m 0,5.570312 h 11.070312 c 0.552285,0 1,0.447715 1,1 0,0.552285 -0.447715,1 -1,1 H 79 c -0.552285,0 -1,-0.447715 -1,-1 0,-0.552285 0.447715,-1 1,-1 z m 0,5.558594 h 11.070312 c 0.552285,0 1,0.447715 1,1 0,0.552285 -0.447715,1 -1,1 H 79 c -0.552285,0 -1,-0.447715 -1,-1 0,-0.552285 0.447715,-1 1,-1 z" />\n' +
        '<path class="audio-icon" transform="translate(0)" d="M95.41,36.79V32.52a11,11,0,1,0-21.89-.69q0,.35,0,.69v4.27a7.32,7.32,0,0,0-1.37,10,6.79,6.79,0,0,0,5.47,2.85H79a1.4,1.4,0,0,0,1.37-1.41h0V36.79A1.41,1.41,0,0,0,79,35.36H77.62a6.82,6.82,0,0,0-1.36.14v-3a8.22,8.22,0,1,1,16.42,0v3a7,7,0,0,0-1.37-.14H89.94a1.4,1.4,0,0,0-1.37,1.43h0v11.4a1.39,1.39,0,0,0,1.36,1.42h1.38a7,7,0,0,0,6.83-7.13,7.21,7.21,0,0,0-2.73-5.69Z"/>\n' +
        '<path class="video-icon" transform="translate(0)" d="m50.39 97.68-6.75-6.68v4.26a2.45 2.45 0 0 1-2.45 2.45h-15.93a2.44 2.44 0 0 1-2.45-2.45v-17.17a2.44 2.44 0 0 1 2.45-2.45h15.91a2.45 2.45 0 0 1 2.45 2.45v4.3l6.74-6.73a1.83 1.83 0 0 1 1.83 1.83v18.38a1.83 1.83 0 0 1-1.8 1.81z"/>\n' +
        '<path class="b6595aa7-5a0a-42af-977e-380dc8b00341" transform="translate(0)" d="M44.3,120.24a59.36,59.36,0,0,0,32.11.52l-16,25.18Z"/>\n' +
        '</svg>',
    iconSize: [40,40],
    popupAnchor: [0,-15],
    className: 'js-mapicon' + typeClass
  });

  return (
    <Marker ref={markerRef} position={position} icon={icon} >
      {highlight === 'milestone' ?
      <Tooltip direction='right' offset={[-8, -2]} opacity={1} permanent interactive = {false} className="jc-poi-number" >
        <span>{highlightcount}</span>
      </Tooltip> : ''}
      <Popup closeButton={false} minWidth={300} autoPan={true}>
        <IonList>
          <IonRow className="popup-header">
            <IonCol>
              <h3>{title}</h3>
              <p>{teaser}</p>
            </IonCol>
          </IonRow>
          <IonButton expand="block" routerLink={"/details/" + uid} color="light">
            Details anzeigen
          </IonButton>
          <IonButton expand="block" color="light" onClick={handleClick}>
            Zu diesem Punkt navigieren
          </IonButton>
        </IonList>
      </Popup>
    </Marker>
  );
};