export interface Position {
  lat: number;
  lng: number;
}

export interface MediaWithDescription {
  description: string;
  src: string;
}

export interface POI {
  uid: number;
  title: string;
  teaser: string;
  position: Position;
  prvImage: MediaWithDescription;
  partnerImage: MediaWithDescription;
  categories: {[key:string]: [{uid: number, title: string}]};
  milestone: Milestone[],
  isCulture: boolean;
  hasText: boolean;
  hasAudio: boolean;
  hasVideo: boolean;
}

export interface Milestone {
  uid: number;
  title: string;
  description: string;
  prvImage: MediaWithDescription;
  partnerImage: MediaWithDescription;
  categories: {[key:string]: [{uid: number, title: string}]};
  type: number //0 normal, 1 Audioguide,
  sorting: number;
}

export interface Ort {
  uid: number;
  title: string;
  description: string;
  image: string;
}

export enum SearchResultType {
  POI = "POI",
  Milestone = "Milestone"
}
export interface SearchResult {
  uid: number;
  title: string;
  description: string;
  prvImage: MediaWithDescription;
  type: SearchResultType;
}

export interface HighlightParams {
  uid: number;
  type: string;
}

export interface Intro {
  pois: POI[];
  text: string[];
}

export interface FilterLabels {
  districts: string;
  time: string;
  themes: string;
  language: string;
  [key: string]: string;
}

export interface EntryReference {
  uid: number;
  title: string;
}