import { fetchJsonData } from './fetchData';
import { parseMediaData } from "./parseMediaData";
import { SearchResult, SearchResultType } from './JCTypes';

export function search(
  callback?: (results: SearchResult[]) => void,
  term: string | null | undefined = ""
) {
  const cb = (data: any) => {
    if ("entries" in data && !Array.isArray(data.entries)) {
      throw new Error("Expected an array of POIs, got: " + data.entries);
    }
    if ("milestones" in data && !Array.isArray(data.milestones)) {
      throw new Error(
        "Expected an array of Milestones, got: " + data.milestones
      );
    }
    const results = parseSearchResults(data.entries, SearchResultType.POI);
    results.push(
      ...parseSearchResults(data.milestones, SearchResultType.Milestone)
    );
    if (callback) callback(results);
  };
  term = term ? term.replace(/ /g, "--") : "";
  fetchJsonData("rest/gmmag-digitalarchiv-entry/poi/rsearch/" + term, cb);
}

const parseSearchResults = (
  data: any,
  type: SearchResultType
): SearchResult[] => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  const results: SearchResult[] = [];
  for (const item of data) {
    if (
      !item.hasOwnProperty("uid") ||
      !item.hasOwnProperty("title") ||
      (type === SearchResultType.POI && !item.hasOwnProperty("teaser")) ||
      (type === SearchResultType.Milestone &&
        !item.hasOwnProperty("description"))
    ) {
      continue;
    }
    results.push({
      uid: item.uid,
      title: item.title,
      description:
        type === SearchResultType.POI ? item.teaser : item.description,
      prvImage: parseMediaData(item.prvImage),
      type
    });
  }
  return results;
};
