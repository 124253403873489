import { serverBaseUrl } from "./fetchData";
import { MediaWithDescription } from "./JCTypes";

export const parseMediaData = (data: any): MediaWithDescription => {
  if (!data) {
    return {
      description: "",
      src: ""
    };
  }
  return {
    description: data.description || "",
    src: data.src ? serverBaseUrl + data.src : ""
  };
};
