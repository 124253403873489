import { EntryReference } from "./JCTypes";

export const parseEntryReferences = (data: any): EntryReference[] => {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  const result: EntryReference[] = [];
  data.forEach(item => {
    if (item.hasOwnProperty("uid") && item.hasOwnProperty("title")) {
      result.push({
        uid: item.uid,
        title: item.title
      });
    }
  });
  return result;
};
