let isMilestoneActive = false;

export function setMilestoneActive(active:boolean) {
  isMilestoneActive = active;
}

export function milestoneActive() {
  return isMilestoneActive;
}

let introDisplayed = true;

export function setIntroShown(){
  introDisplayed = false;
}

export function showIntro() {
  return introDisplayed;
}