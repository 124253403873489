import { fetchJsonData, serverBaseUrl } from './fetchData';
import {Intro, Milestone, Ort, POI} from './JCTypes';
import { parseEntryReferences } from './parseEntryReferences';
import { parseMediaData } from './parseMediaData';

let pois:POI[] | null  = null;

export function fetchPOIs(callback?: (pois: POI[]) => void, forceReload = false) {
  const cb = (data: any) => {
    if (!Array.isArray(data)) {
      throw new Error('Expected an array of POIs, got: ' + data);
    }

    pois = parseReceivedPOIs(data);

    if(callback) callback(pois);
  };

  if (pois == null || forceReload) {
    fetchJsonData(
        'rest/gmmag-digitalarchiv-entry/poi/rlist',
        cb
    );
  } else {
    if(callback) callback(pois);
  }

}

export function fetchIntro(
  callback?: (intro: Intro) => void,
  quantity = 1
) {
  const cb = (data: any) => {
    if (!data.hasOwnProperty("entries") || !data.hasOwnProperty("intro")) {
      throw new Error("Expected an Intro, got: " + data);
    }

    const result = parseReceivedPOIs(data.entries);

    if (callback) callback({
      pois: result,
      text: data.intro
    });
  };

  fetchJsonData("rest/gmmag-digitalarchiv-entry/poi/rrandom/" + quantity, cb);
}

const parseReceivedPOIs = (receivedPOIs: any): POI[] => {
  const pois: POI[] = [];
  for (const item of receivedPOIs) {
    if (
      !item.hasOwnProperty("uid") ||
      !item.hasOwnProperty("title") ||
      !item.hasOwnProperty("latitude") ||
      !item.hasOwnProperty("longitude")
    ) {
      continue;
    }
    pois.push({
      uid: item.uid,
      title: item.title,
      teaser: item.teaser || "",
      position: { lat: item.latitude, lng: item.longitude },
      prvImage: parseMediaData(item.prvImage),
      partnerImage: parseMediaData(item.partnerImage),
      categories: item.categories,
      milestone: item.milestone,
      isCulture: !!item.isCulture,
      hasText: !!item.hasText,
      hasAudio: !!item.audios,
      hasVideo: !!item.videos
    });
  }
  return pois;
}

export function getPOIsFiltered(callback: (pois:POI[])=>void, filter: {liste?: {uid:number, type: string}, categories?:{}}, forceReload = false) {
  fetchPOIs((points) => {
    callback(points.filter((poi) => {

      let listeMatch = false;
      let categoryMatch = false;

      if (filter.liste) {
        if (filter.liste.type === 'ort') {
          if (poi.categories.places && poi.categories.places.length) {
            for(let i in poi.categories.places) {
              if (poi.categories.places[i].uid === filter.liste.uid) {
                listeMatch = true;
              }
            }
          }
        } else if (filter.liste.type === 'milestone') {
          if (poi.milestone && poi.milestone.length) {
            for(let i in poi.milestone) {
              if (poi.milestone[i].uid === filter.liste.uid) {
                listeMatch = true;
              }
            }
          }
        }
      }
      else {
        listeMatch = true;
      }

      if(!filter.categories) categoryMatch = true;

      return listeMatch && categoryMatch;
    })



    );
  },forceReload);
}

export function getPOIById(id: number) {
  if(pois != null) return pois.filter((poi)=> {return poi.uid === id;})[0];
  return null;
}

let orte:Ort[] | null = null;

export function fetchOrte(callback: (orte: Ort[]) => void, forceReload = false) {
  const cb = (data: any) => {
    if (!Array.isArray(data)) {
      throw new Error('Expected an array of Ort, got: ' + data);
    }

    orte = [];
    for (const item of data) {
      if (
          !item.hasOwnProperty('uid') || !item.hasOwnProperty('title')
      ) {
        continue;
      }
      orte.push({
        uid: item.uid,
        title: item.title,
        description: item.description || '',
        image: serverBaseUrl + item.image || ''
      });
    }

    callback(orte);
  };

  if (orte == null || forceReload) {
    fetchJsonData(
        'rest/gmmag-digitalarchiv-entry/poi/rplaces',
        cb
    );
  } else {
    callback(orte);
  }

}

export function getListenname(uid:number, type: string, callback: (name: string)=>void) {
  if (type === 'milestone') {
      fetchMilestones(milestones => {
        callback(milestones.filter((ms) => ms.uid === uid)[0].title)
      });
  } else if (type === 'ort') {
    fetchOrte(orte => {
      callback(orte.filter((ort) => ort.uid === uid)[0].title)
    });
  }
}


export function getOrtsnameByPoiUid(poiUid:number, callback: (name: string)=>void) {
  if(pois != null) {
    let poi = pois.filter((poi)=>{
      return poi.uid === poiUid;
    })[0];

    if (poi && orte != null) {
      if (poi.categories && poi.categories.places && poi.categories.places.length) {
        getListenname(poi.categories.places[0].uid, 'ort', callback);
      } else {
        return 'Ortsangabe fehlt';
      }
    }
  }
  return 'Der Ort zum Eintrag mit ' + poiUid + ' ist unbekannt';
}

interface PoiDetailsList {
  [key: number]: any;
}
const details:PoiDetailsList = {};

export function fetchDetails(uid:number, callback: (details: any) => void, forceReload = false) {
  const cb = (data: any) => {

    if (
        //!data.hasOwnProperty('uid') ||
        !data.hasOwnProperty('title')

    ) {
      throw new Error('Expected a details object, got: ' + data);
    }
    details[uid] = {
      uid: data.uid || 1,
      title: data.title,
      roofline: data.roofline,
      description: data.description || '',
      prvImage: parseMediaData(data.prvImage),
      videos: data.videos || [],
      images: data.images ? data.images.map((img:any)=> parseMediaData(img)) : [],
      audios: data.audios ? data.audios.map((audio: any)=>parseMediaData(audio)) : [],
      files: data.file ? data.file.map((url:string)=>serverBaseUrl+url) : [],
      milestones: data.milestones ? parseEntryReferences(data.milestones) : [],
    };

    callback(details[uid]);
  };

  fetchOrte(()=>{
    if (!(uid in details) || forceReload) {
      fetchJsonData(
          'rest/gmmag-digitalarchiv-entry/poi/rshow/'+uid,
          cb
      );
    } else {
      callback(details[uid]);
    }
  });
}

export function cropText(text:string, wc:number = 25) {
  const words = text.split(' ');
  if (words.length > wc) {
    return words.slice(0, wc).join(' ') + ' ...';
  }
  return text;
}


let milestones:Milestone[] | null = null;
export function fetchMilestones(callback: (milestones: Milestone[]) => void, forceReload = false) {
  const cb = (data: any) => {
    if (!Array.isArray(data)) {
      throw new Error('Expected an array of Milestone, got: ' + data);
    }

    milestones = [];
    for (const item of data) {
      if (
          !item.hasOwnProperty('uid') || !item.hasOwnProperty('title')
      ) {
        continue;
      }
      milestones.push({
        uid: item.uid,
        title: item.title,
        description: item.desrcription || '',
        prvImage: parseMediaData(item.prvImage),
        partnerImage: parseMediaData(item.partnerImage),
        type: item.source,
        categories: item.categories || {},
        sorting: 0
      });
    }

    callback(milestones);
  };

  if (milestones == null || forceReload) {
    fetchJsonData(
        'rest/gmmag-digitalarchiv-entry/milestone/rlist/',
        cb
    );
  } else {
    callback(milestones);
  }
}

export function getMilestoneName(uid:number) {
  if(milestones) {
    return milestones.filter((ms)=> {
      return ms.uid === uid;
    })[0].title;
  }
  return '';
}
