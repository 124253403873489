import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import {IonReactHashRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { compass, flag, map } from 'ionicons/icons';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Details from "./pages/Details";
import Karte from './pages/Karte';
import Liste from './pages/Liste';
import Orte from './pages/Orte';
import Touren from './pages/Touren';
import './theme.css';
/* Theme variables */
import './theme/variables.css';



const App: React.FC = () => (
  <IonApp>
    <IonReactHashRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/karte/:typ/:uid" component={Karte}/>
          <Route path="/touren" component={Touren} exact={true}/>
          <Route path="/orte" component={Orte} exact={true}/>
          <Route path="/liste/:type/:uid" component={Liste} />
          <Route path="/details/:poiuid" component={Details} exact={true}/>
          <Route path="/" render={() => <Redirect to="/karte/0/0" />} exact={true} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="karte" href="/karte/0/0">
            <IonIcon icon={map} />
            <IonLabel>Karte</IonLabel>
          </IonTabButton>
          <IonTabButton tab="touren" href="/touren">
            <IonIcon icon={compass} />
            <IonLabel>Touren</IonLabel>
          </IonTabButton>
          <IonTabButton tab="orte" href="/orte">
            <IonIcon icon={flag} />
            <IonLabel>Orte</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactHashRouter>
  </IonApp>
);

export default App;
