import { fetchJsonData } from "./fetchData";
import { EntryReference, FilterLabels } from "./JCTypes";
import { parseEntryReferences } from "./parseEntryReferences";

const defaultFilterLabels: FilterLabels = {
  districts: "District",
  time: "Time",
  themes: "Themes",
  language: "Language"
};
let filterLabels: FilterLabels;

export function fetchFilterLabels(
  callback?: (result: FilterLabels) => void,
  forceReload = false
) {
  const cb = (data: any) => {
    filterLabels = defaultFilterLabels;
    Object.keys(defaultFilterLabels).forEach(key => {
      if (data.hasOwnProperty(key)) {
        filterLabels[key] = data[key];
      }
    });
    if (callback) {
      callback(filterLabels);
    }
  };
  if (!filterLabels || forceReload) {
    fetchJsonData("rest/gmmag-digitalarchiv-entry/category/rmapping/de", cb);
  } else {
    if (callback) callback(filterLabels);
  }
}

let moreMenu: EntryReference[] = [];

export function fetchMoreMenu(callback: (result: EntryReference[]) => void) {
  const cb = (data: any) => {
    moreMenu = parseEntryReferences(data);

    callback(moreMenu);
  };

  if (moreMenu.length === 0) {
    fetchJsonData("rest/gmmag-digitalarchiv-entry/content/rmenu", cb);
  } else {
    callback(moreMenu);
  }
}

export function fetchContent(callback: (result: string[]) => void, uid: number) {
  const cb = (data: any) => {
    if (!Array.isArray(data)) {
      //throw new Error('Expected an array, got: ' + data);
      data = ["Leider kam es zu einem Server-Fehler, bitte erneut versuchen."];
    }

    callback(data);
  };

  fetchJsonData("rest/gmmag-digitalarchiv-entry/content/rpage/" + uid, cb);
}
