import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { MoreMenu } from "./MoreMenu";
import React from "react";
import { SearchButton } from "./SearchButton";

export const AppHeader: React.FC = () => {
  return (
    <IonHeader>
      <IonToolbar text-center>
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>
        <IonTitle>
          <img src="assets/stibbin-2.svg" height="30" alt="stibbIn" />
        </IonTitle>
        <IonButtons slot="end">
          <SearchButton />
          <MoreMenu />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
