import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonIcon, IonItem, IonModal, IonRow, IonSearchbar, IonTitle, IonToolbar } from "@ionic/react";
import { close, search as searchIcon } from "ionicons/icons";
import React, { useState } from "react";
import { search } from "../helpers/search";
import { cropText } from "../helpers/fetchEntryData";
import { SearchResult, SearchResultType } from "../helpers/JCTypes";

export const SearchButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);

  const searchbar = React.useRef<HTMLIonSearchbarElement>(null);

  const searchTerm = () => {
    if (searchbar.current && searchbar.current.value) {
      try {
        search((results: SearchResult[]) => {
          if (results.length > 0) {
            setResults(results);
          }
        }, searchbar.current.value);
      } catch (error) {
        setResults([]);
      }
    }
  };

  const resultList = () => {
    const createRouterLink = (type: SearchResultType, uid: number): string => {
      switch (type) {
        case SearchResultType.POI:
          return "/details/" + uid;
        case SearchResultType.Milestone:
          return "/liste/milestone/" + uid;
      }
    };

    return (
      <div className="jc-margin-20">
        {results.map(result => {
          return (
            <IonRow key={result.uid}>
              <IonItem routerLink={createRouterLink(result.type, result.uid)} onClick={() => setShowModal(false)}>
                <IonCol size="4">
                  <div className="responsive-image">
                    <img src={result.prvImage.src} alt={""} />
                  </div>
                </IonCol>
                <IonCol size="8">
                  <h3>
                    {result.title + ' '}
                    {result.type === SearchResultType.Milestone ? <IonBadge slot="end">Tour</IonBadge> : null}
                  </h3>
                  <p>{cropText(result.description)}</p>
                </IonCol>
              </IonItem>
            </IonRow>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonToolbar>
          <IonTitle>Suche</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent>{resultList()}</IonContent>
        <IonSearchbar
          ref={searchbar}
          autocorrect={"on"}
          placeholder="Suche nach ..."
          showCancelButton="never"
          onIonChange={searchTerm}
        />
      </IonModal>

      <IonButton onClick={e => setShowModal(true)}>
        <IonIcon icon={searchIcon} />
      </IonButton>
    </>
  );
};
