import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { locate } from "ionicons/icons";
import { latLngBounds, LatLngExpression } from "leaflet";
import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { getPOIById } from "../helpers/fetchEntryData";
import { HighlightParams, POI, Position } from '../helpers/JCTypes';
import POIMarker from './POIMarker';

export interface Props {
  pois: POI[];
  highlight: HighlightParams;
}

const defaultCenter: Position = { lat: 50.99747, lng: 9.73345 };

export const LeafletMap = ({ pois, highlight }: Props) => {
  //Problem 1 (evtl auch kein problem): Kind ..didMount wird vor Parent ..DidMount aufgerufen - hängt das Kindelement dann wirklich schon im DOM?
  //Problem 2 in diesem speziellen Fall: Nur, weil ein Element im DOM eingehängt ist, heisst es noch nicht, dass es auch schon fertig gezeichnet ist und eine Höhe hat... daher folgender Workaround
  React.useEffect(() => {
    setTimeout(function() {
      requestAnimationFrame(function() {
        window.dispatchEvent(new Event('resize'));
      });
    }, 250);
  }, [highlight]);

  let zoom = 8;
  let center = defaultCenter;
  if(highlight && highlight.uid > 0){
    let centerPOI = getPOIById(highlight.uid);
    if(centerPOI) {
      center = centerPOI.position;
      zoom = 14;
    }
  } else {
    window.setTimeout(()=>{
      if(mapRef.current) {
        mapRef.current.leafletElement.setView(center, zoom);
      }
    }, 300);
  }

  const mapRef = React.createRef<Map>();
  const viewportPositions = React.useRef<LatLngExpression[]>([]);

  const centerMap = React.useCallback(() => {
    if (viewportPositions.current.length >= 2) {
      const bounds = latLngBounds(viewportPositions.current);
      if (mapRef.current) {
        mapRef.current.leafletElement.fitBounds(bounds);
      }
    }
  }, [mapRef]);

  const Markers = React.useMemo(() => {
    viewportPositions.current = [];
    let highlightCount = 0;

    const markers: React.ReactNode[] = pois.map(poi => {
      let currHighlight = "all";
      if (highlight) {
        switch (highlight.type) {
          case "milestone":
            currHighlight = "muted";
            if (poi.milestone && poi.milestone.length) {
              poi.milestone.forEach(ms => {
                if (ms.uid === highlight.uid) {
                  currHighlight = "milestone";
                  ++highlightCount;
                  viewportPositions.current.push(poi.position);
                }
              });
            }
            break;

          case "poi":
            if (highlight.uid > 0) {
              if (highlight.uid === poi.uid) {
                currHighlight = "single";
              } else {
                currHighlight = "muted";
              }
            }
            break;
        }
      }

      if (highlight.type === "milestone" && currHighlight === "muted")
        return "";
      return (
        <POIMarker
          key={poi.uid}
          uid={poi.uid}
          title={poi.title}
          teaser={poi.teaser}
          position={poi.position}
          highlight={currHighlight}
          highlightcount={highlightCount}
          isCulture={poi.isCulture}
          hasText={poi.hasText}
          hasAudio={poi.hasAudio}
          hasVideo={poi.hasVideo}
        />
      );
    });

    window.setTimeout(() => {
      centerMap();
    }, 300);

    return <>{markers}</>;
  }, [pois, highlight, centerMap]);

  return (
    <>
      <Map
        ref={mapRef}
        center={center}
        zoom={zoom}
        maxZoom={18}
        className="jc-nopadding jc-nomargin"
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>{Markers}</MarkerClusterGroup>
      </Map>
      {highlight.type === 'milestone' ? (
        <IonFab vertical="center" horizontal="end" slot="fixed" onClick={() => centerMap()}>
          <IonFabButton className="fab-blue">
            <IonIcon icon={locate} />
          </IonFabButton>
        </IonFab>
      ) : null}
    </>
  );
};
