export const serverBaseUrl = "https://www.archive-region.de/";

export function fetchJsonData(url: string, callback: (data: any) => void) {
  url = serverBaseUrl + url;
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(data => {
      callback(data);
    })
    .catch(error => {
      console.error(error);
    });
}
