import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonRow,
  IonSlide,
  IonSlides,
  IonSpinner,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonToast,
} from "@ionic/react";
import { compass, download, locate } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { AppHeader } from "../components/AppHeader";
import { milestoneActive } from "../helpers/Context";
import { fetchDetails, getOrtsnameByPoiUid } from "../helpers/fetchEntryData";
import { MediaWithDescription, EntryReference } from "../helpers/JCTypes";
import "./Details.css";

interface DetailsPageProps
  extends RouteComponentProps<{
    poiuid: string;
  }> {}

const Details: React.FC<DetailsPageProps> = ({ match }) => {
  const [ortsname, setOrtsname] = useState("");
  const [details, setDetails] = useState<any>(null);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastText, setToastText] = useState('');

  const doRefresh = (
    event: CustomEvent<RefresherEventDetail> | null,
    forceReload = false
  ) => {
    let uid = parseInt(match.params.poiuid);
    fetchDetails(
      uid,
      data => {
        if (data) {
          setDetails(data);
          getOrtsnameByPoiUid(uid, setOrtsname);
          if (event) event.detail.complete();
        }
      },
      forceReload
    );
    if (event) event.detail.complete();
  };

  useIonViewWillEnter(() => {
    setOrtsname("");
    setDetails(null);
  }, [match]);

  useIonViewDidEnter(() => {
    doRefresh(null);
  }, [match]);

  useIonViewWillLeave(() => {
    const removeElements = function (tagname: string) {
      let elements = document.getElementsByTagName(tagname),
        index;
      for (index = elements.length - 1; index >= 0; index--) {
        let parentNode = elements[index].parentNode;
        if (parentNode != null) {
          parentNode.removeChild(elements[index]);
        }
      }
    };
    removeElements("audio");
    removeElements("iframe");
  });

  const showUrheber = (event: React.MouseEvent, info: string) => {
    event.preventDefault();
    event.stopPropagation();
    setToastText(info);
    setToastVisible(true)
  }

  const createContent = () => {
    return (
      <>
        <IonContent>
          <IonRefresher
            slot="fixed"
            onIonRefresh={e => {
              doRefresh(e, true);
            }}
          >
            <IonRefresherContent refreshingSpinner="dots" />
          </IonRefresher>
          <IonGrid>
            <IonRow className="list-header">
              <IonCol size="12" size-lg="4" className="sticky-title">
                <IonRow>
                  <IonCol size="12">
                    <h3>
                      <p className="breadcrumb">{ortsname}</p>
                      {details == null ? (
                        <IonSpinner name="dots" color="medium" />
                      ) : (
                        details.title
                      )}
                    </h3>
                    {/*</IonCol>
                  <IonCol className="ion-align-self-center to-map-btn ion-text-right">*/}
                    {milestoneActive() ? (
                      ""
                    ) : (
                      <IonButton
                        routerLink={"/karte/poi/" + match.params.poiuid}
                      >
                        <IonIcon icon={locate} /> auf Karte
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>

              {details == null ? (
                ""
              ) : (
                <>
                  <IonCol
                    size="12"
                    size-lg="4"
                    offset-lg="-4"
                    class="ion-align-self-center"
                  >
                    <div className="responsive-image" onClick={(event) => {showUrheber(event, details.prvImage.description)}}>
                      <img src={details.prvImage.src} alt="" />
                    </div>
                  </IonCol>

                  <IonCol size="12" size-lg="8">
                    {details.milestones && details.milestones.length ? (
                      <IonRow className="tour-teile">
                        <h3>
                          <IonIcon icon={compass} /> Dieser POI/Beitrag ist Teil
                          der Tour
                        </h3>
                        {details.milestones.map((milestone: EntryReference) => {
                          return (
                            <IonRouterLink
                              key={milestone.uid}
                              className="tour-link"
                              routerLink={"/liste/milestone/" + milestone.uid}
                            >
                              • {milestone.title}
                            </IonRouterLink>
                          );
                        })}
                      </IonRow>
                    ) : null}
                    <IonRow>
                      <h3>{details.roofline}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: details.description
                        }}
                      />
                    </IonRow>
                  </IonCol>

                  {details.images && details.images.length ? (
                    <IonCol
                      className="additional-info-col"
                      size="12"
                      size-lg="6"
                    >
                      <h4>Bildergalerie</h4>
                      <IonSlides pager={true} options={null}>
                        {details.images.map((img: MediaWithDescription, index: number) => {
                          return (
                            <IonSlide key={"img-" + index}>
                              <img onClick={(event) => {showUrheber(event, img.description)}} src={img.src} alt={"Bild " + index} />
                            </IonSlide>
                          );
                        })}
                      </IonSlides>
                    </IonCol>
                  ) : (
                    ""
                  )}
                  {details.videos.map((video: string, index: number) => {
                    return (
                      <IonCol
                        key={"video-" + index}
                        size="12"
                        size-lg="6"
                        className="additional-info-col"
                      >
                        <h4>Video-Inhalte</h4>
                        <div className="responsive-video">
                          <iframe
                            title={"video-" + index}
                            src={video}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      </IonCol>
                    );
                  })}

                  {details.audios && details.audios.length ? (
                    <IonCol
                      size="12"
                      size-lg="4"
                      className="additional-info-col"
                    >
                      <h4>Audio-Inhalte</h4>
                      <IonList lines="none">
                        {details.audios.map(
                          (audio: MediaWithDescription, index: number) => {
                            return (
                              <IonItem key={"audio-" + index}>
                                <audio
                                  src={audio.src}
                                  controls={true}
                                  controlsList="nodownload"
                                  className="slider-audio"
                                />
                                <div className="slider-audio">
                                  {audio.description}
                                </div>
                              </IonItem>
                            );
                          }
                        )}
                      </IonList>
                    </IonCol>
                  ) : (
                    ""
                  )}
                  {details.files && details.files.length ? (
                    <IonCol
                      size="12"
                      size-lg="4"
                      className="additional-info-col"
                    >
                      <h4>Dateien und Dokumente</h4>
                      <ul>
                        {details.files.map((url: string, index: number) => {
                          return (
                            <li key={"file-" + index}>
                              <IonIcon icon={download} item-start />
                              <a href={url}>Datei {index}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </IonCol>
                  ) : (
                    ""
                  )}
                </>
              )}
            </IonRow>
          </IonGrid>
          <IonToast
              isOpen={toastVisible}
              onDidDismiss={() => setToastVisible(false)}
              message={toastText}
              duration={2000}
          />
        </IonContent>
      </>
    );
  };

  return (
    <IonPage>
      <AppHeader />
      {createContent()}
    </IonPage>
  );
};

export default Details;
