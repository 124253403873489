import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonPopover,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from "@ionic/react";
import { close, ellipsisVertical } from "ionicons/icons";
import React, { useRef, useState } from "react";
import { fetchContent, fetchMoreMenu } from "../helpers/fetchAppData";
import { EntryReference } from "../helpers/JCTypes";

export const MoreMenu: React.FC = () => {
  const [menu, setMenu] = useState<EntryReference[]>([]);
  const [showMenu, setShowMenu] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined
  });
  const [showModal, setShowModal] = useState(false);
  const modalHeader = useRef<string>("Inhalte werden geladen");
  const modalContent = useRef<string>("<p>Kein Inhalt</p>");

  useIonViewDidEnter(() => {
    fetchMoreMenu((result: EntryReference[]) => {
      setMenu(result);
    });
  });

  const createClickHandler = (uid: number, title: string) => (
    e: React.MouseEvent
  ) => {
    setShowMenu({ open: false, event: e.nativeEvent });
    modalHeader.current = title;
    modalContent.current = "";
    setShowModal(true);

    fetchContent((result: string[]) => {
      modalContent.current = result.join("\n");
      setShowModal(true);
    }, uid);
  };

  return (
    <>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonToolbar>
          <IonTitle>{modalHeader.current}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setShowModal(false);
              }}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent>
          {modalContent.current == "" ? <div className="jc-margin-20"><IonSpinner name="dots" color="medium" /></div> :
          <div className="jc-margin-20" dangerouslySetInnerHTML={{ __html: modalContent.current }} /> }
        </IonContent>
      </IonModal>
      <IonPopover
        isOpen={showMenu.open}
        event={showMenu.event}
        onDidDismiss={e => setShowMenu({ open: false, event: e })}
      >
        <IonList>
          {menu.map(m => (
            <IonItem key={m.uid} onClick={createClickHandler(m.uid, m.title)}>
              {m.title}
            </IonItem>
          ))}
        </IonList>
      </IonPopover>
      <IonButton
        onClick={e => setShowMenu({ open: true, event: e.nativeEvent })}
      >
        <IonIcon icon={ellipsisVertical} />
      </IonButton>
    </>
  );
};
