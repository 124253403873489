import { RefresherEventDetail } from "@ionic/core";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  IonThumbnail,
  useIonViewDidEnter,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { AppHeader } from "../components/AppHeader";
import { setMilestoneActive } from "../helpers/Context";
import { fetchOrte } from "../helpers/fetchEntryData";
import { Ort } from "../helpers/JCTypes";

const Orte: React.FC = () => {
  const [orte, setOrte] = useState<Ort[]>([]);
  const [toastVisible, setToastVisible] = useState(false);
  const [toastText, setToastText] = useState('');

  const doRefresh = (
    event: CustomEvent<RefresherEventDetail> | null,
    forceReload = false
  ) => {
    fetchOrte((orte: Ort[]) => {
      if (orte.length > 0) {
        setOrte(orte);
        if (event) event.detail.complete();
      }
    }, forceReload);
  };

  useIonViewDidEnter(() => {
    setMilestoneActive(false);
    doRefresh(null);
  });

  const showUrheber = (event: React.MouseEvent, info: string) => {
    event.preventDefault();
    event.stopPropagation();
    setToastText(info);
    setToastVisible(true)
  }

  const createContent = () => {
    return (
      <>
        {orte.length ? (
          <IonGrid>
            <IonRow className="jc-nopadding">
              {orte.map((ort, index) => {
                return (
                  <IonCol key={ort.uid} size="12" sizeMd="6" sizeLg="4">
                    <IonItem routerLink={"/liste/ort/" + ort.uid}>
                      <IonRow>
                        <IonCol size="4">
                          <img src={ort.image} alt={""} style={{ objectFit: 'contain' }} onClick={(event) => {
                            //nicht implementiert, da Ortsbilder bisher keine Description haben
                            //showUrheber(event, ort.image.description);
                          }} />
                        </IonCol>
                        <IonCol size="8">
                          <IonLabel>
                            <h3>{ort.title}</h3>
                            <div>{ort.description}</div>
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        ) : null}
      </>
    );
  };

  return (
    <IonPage>
      <AppHeader />
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={e => {
            doRefresh(e, true);
          }}
        >
          <IonRefresherContent refreshingSpinner="dots" />
        </IonRefresher>
        <IonRow className="list-header">
          <IonCol>
            <h3>Orte</h3>{" "}
            {orte.length === 0 ? (
              <IonSpinner name="dots" color="medium" />
            ) : null}
          </IonCol>
        </IonRow>
        {createContent()}
        <IonToast
            isOpen={toastVisible}
            onDidDismiss={() => setToastVisible(false) }
            message={toastText}
            duration={2000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Orte;
