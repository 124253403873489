import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, {useEffect} from "react";
import { RouteComponentProps } from "react-router";
import { AppHeader } from "../components/AppHeader";
import { LeafletMap } from "../components/LeafletMap";
import { setIntroShown, setMilestoneActive, showIntro } from "../helpers/Context";
import { fetchIntro, fetchPOIs } from "../helpers/fetchEntryData";
import { Intro, POI } from "../helpers/JCTypes";
import "./Karte.css";
import {Plugins} from "@capacitor/core";

interface ListPageProps
  extends RouteComponentProps<{
    typ: string;
    uid: string;
  }> {}

const Karte: React.FC<ListPageProps> = ({ match }) => {
  const [pois, setPOIs] = React.useState<POI[]>([]);
  const [introPois, setIntroPOIs] = React.useState<POI[]>([]);
  const [introText, setIntroText] = React.useState<string[]>([]);
  const [showOfflineAlert, setShowOfflineAlert] = React.useState(false);
  const [introShowing, setIntroShowing] = React.useState(true);

  const highlight = { uid: parseInt(match.params.uid), type: match.params.typ };

  useEffect(() => {
    if (isPlatform('android')) {
      Plugins.App.addListener("backButton", (e) => {
        if (window.location.hash === "#/" || window.location.hash === "#/karte/0/0") {
            Plugins.App.exitApp();
        }
      });
    }
  }, []);

  useIonViewDidEnter(() => {
    //das darf erst hier passieren, weil vorher noch die alten match parameter bestehen
    if (match.params.typ === "0") {
      setMilestoneActive(false);
    }

    try {
      fetchPOIs((newPois: POI[]) => {
        if (newPois.length > 0) {
          setPOIs(newPois);
        }
      });
      fetchIntro((intro: Intro) => {
        if (intro.pois.length > 0) {
          setIntroPOIs(intro.pois);
        }
        if (intro.text.length > 0) {
          setIntroText(intro.text);
        }
      });
    } catch (error) {
      setShowOfflineAlert(true);
    }
  }, [match]);

  const createIntro = () => {
    return (
      <IonModal isOpen={introShowing} onDidDismiss={() => setIntroShown()}>
        <IonToolbar>
          <IonTitle>Tipp des Tages</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setIntroShowing(false);
              }}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent>
          <div className="jc-margin-20">
            {introPois.map(p => (
              <IonRow key={p.uid}>
                <IonItem
                  routerLink={"/details/" + p.uid}
                  onClick={() => {
                    setIntroShowing(false);
                  }}
                ><IonRow>
                  <IonCol size="12" sizeSm="4">
                    <div className="responsive-image">
                      <img src={p.prvImage.src} alt={""} />
                    </div>
                  </IonCol>
                  <IonCol size="12" sizeSm="8">
                    <h3>{p.title}</h3>
                    <p>{p.teaser}</p>
                  </IonCol>
                </IonRow>
                </IonItem>
              </IonRow>
            ))}
            {introText.map((t, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: t }} />
            ))}
          </div>
        </IonContent>
      </IonModal>
    );
  };

  return (
    <IonPage>
      <AppHeader />
      <IonContent>
        <LeafletMap pois={pois} highlight={highlight} />
      </IonContent>
      <IonAlert
        isOpen={showOfflineAlert}
        onDidDismiss={() => setShowOfflineAlert(false)}
        header={"Warnung"}
        subHeader={"Server nicht erreichbar"}
        message={"Bitte überprüfen Sie Ihre Internet-Verbindung."}
        buttons={["OK"]}
      />
      {showIntro() ? createIntro() : ""}
    </IonPage>
  );
};

export default Karte;
