import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonToast, IonImg,
} from "@ionic/react";
import { close, filter, locate } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { AppHeader } from "../components/AppHeader";
import { setMilestoneActive } from "../helpers/Context";
import { getListenname, getPOIsFiltered, cropText } from "../helpers/fetchEntryData";
import { fetchFilterLabels } from "../helpers/fetchAppData";
import { FilterLabels, POI } from "../helpers/JCTypes";

interface ListPageProps
  extends RouteComponentProps<{
    uid: string;
    type: string;
  }> {}

const Liste: React.FC<ListPageProps> = ({ match }) => {
  const [listenname, setListenname] = useState("");
  const [pois, setPois] = useState<POI[]>([]);
  const [hinweis, setHinweis] = useState<string>("");
  const [toastVisible, setToastVisible] = useState(false);
  const [toastText, setToastText] = useState('');

  const [filtersvisible, setFiltersvisible] = useState(false);

  const [filterActive, setFilterActive] = useState(false);

  const [katfilter, setkatfilter] = useState<{ [key: string]: string[] }>({});
  const [filterLabels, setFilterLabels] = useState<FilterLabels>();

  //Refs für fix vier Filter-Kategorien, ggf. Anzahl erhöhen
  const selectRefs = [
    React.createRef<HTMLIonSelectElement>(),
    React.createRef<HTMLIonSelectElement>(),
    React.createRef<HTMLIonSelectElement>(),
    React.createRef<HTMLIonSelectElement>()
  ];

  const updateFilter = () => {
    let hasFilterVals = false;
    const tmpFilter: { [key: string]: any } = {};
    selectRefs.forEach(ref => {
      if (ref.current) {
        const classList = ref.current.classList;
        if (classList.item(0) != null) {
          const className = classList.item(0);
          if (className != null) {
            const cat = className.substr("cat-".length);
            tmpFilter[cat] = ref.current.value;
            hasFilterVals = hasFilterVals || ref.current.value > 0;
          }
        }
      }
    });
    setFilterActive(hasFilterVals);
    setkatfilter(tmpFilter);
  };

  const type = match.params.type;

  const uid = parseInt(match.params.uid);

  const doRefresh = (
    event: CustomEvent<RefresherEventDetail> | null,
    forceReload = false
  ) => {
    getListenname(uid, type, setListenname);
    getPOIsFiltered(
      pois => {
        if (pois.length === 0) {
          setHinweis("Keine Einträge gefunden");
        }

        //Sortieren, falls es ein Milestone ist
        if (type == "milestone") {
          pois = pois.sort((a,b) => {
            return a.milestone.filter(m => {return m.uid == uid})[0].sorting - b.milestone.filter(m => {return m.uid == uid})[0].sorting;
          });
        }

        setPois(pois);
        if (event) event.detail.complete();
      },
      { liste: { uid: uid, type: type } },
      forceReload
    );
    fetchFilterLabels((result: FilterLabels) => {
      setFilterLabels(result);
    }, forceReload);
  };

  useIonViewWillEnter(() => {
    setListenname("");
    setHinweis("");
    setPois([]);
    resetFilter();
  }, [match]);

  useIonViewDidEnter(() => {
    //das darf erst hier passieren, weil vorher noch die alten match parameter bestehen
    setMilestoneActive(match.params.type === "milestone");
    doRefresh(null);
  }, [match]);

  const passFilter = (poi: POI) => {
    for (const cat in katfilter) {
      let filterPassed = false;
      if (katfilter[cat] && katfilter[cat].length) {
        if (poi.categories[cat] && poi.categories[cat].length) {
          poi.categories[cat].forEach(function (poiVal) {
            if (katfilter[cat].indexOf(poiVal.uid.toString()) > -1) {
              filterPassed = true;
            }
          });
        }
        if (!filterPassed) return "";
      }
    }

    const showUrheber = (event: React.MouseEvent, info: string) => {
      event.preventDefault();
      event.stopPropagation();
      setToastText(info);
      setToastVisible(true)
    }

    return (
      <IonCol key={poi.uid} size="12" sizeMd="6" sizeLg="4">
        <IonItem routerLink={"/details/" + poi.uid}>
          <IonRow>
            <IonCol size="4">
              <img src={poi.prvImage.src} alt={""} style={{ objectFit: 'contain' }} onClick={(event) => {
                showUrheber(event, poi.prvImage.description);
              }} />
              <img src={poi.partnerImage.src} alt={""} style={{ marginTop: 8, objectFit: 'contain'  }} />
            </IonCol>
            <IonCol size="8">
              <IonLabel>
                <h3>{poi.title}</h3>
                <div>{cropText(poi.teaser)}</div>
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonItem>
      </IonCol>
    );
  };

  const resetFilter = () => {
    setFiltersvisible(false);
    selectRefs.forEach(function (ref) {
      if (ref.current) {
        ref.current.value = [];
      }
    });
    updateFilter();
  };

  const createFilter = () => {
    const filter: { [key: string]: any } = {};

    //vorhandene Kategorien und Werte aus Daten ermitteln
    pois.forEach((poi, index) => {
      if (poi.categories) {
        for (const key in poi.categories) {
          if (key === "places") continue;
          if (!(key in filter)) {
            filter[key] = {};
          }
          for (const index in poi.categories[key]) {
            const filterVal = poi.categories[key][index];
            if (!(filterVal.uid in filter[key])) {
              filter[key][filterVal.uid] = filterVal.title;
            }
          }
        }
      }
    });

    //Markup zusammenbauen
    const effCategories = Object.keys(filter);

    //manuell umsortieren, damit gewünschte reihenfolge passt
    const categories : string[] = [];
    if (effCategories.includes('themes')) categories.push('themes');
    if (effCategories.includes('time')) categories.push('time');
    if (effCategories.includes('districts')) categories.push('districts');

    return pois.length ? (
      <div className="jc-nomargin">
        {categories.length ? (
          categories.map((cat, index) => {
            return (
              <IonItem key={cat}>
                <IonLabel>
                  {filterLabels && cat in filterLabels
                    ? filterLabels[cat]
                    : cat}
                </IonLabel>
                <IonSelect
                  class={"cat-" + cat}
                  ref={selectRefs[index]}
                  okText="Filter setzen"
                  cancelText="Abbrechen"
                  multiple
                  onIonChange={updateFilter}
                  value={katfilter[cat] || null}
                >
                  {Object.keys(filter[cat]).map(val => {
                    return (
                      <IonSelectOption key={val} value={val}>
                        {filter[cat][val]}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            );
          })
        ) : (
          <IonItem>
            Für die Einträge auf dieser Seite wurden keine Kategorien vergeben
          </IonItem>
        )}

        <IonRow className="list-header">
          <IonCol>
            <IonButton
              fill="outline"
              color="light"
              expand="full"
              className="ion-text-right text-muted"
              onClick={() => {
                resetFilter();
              }}
            >
              Filter zurücksetzen
            </IonButton>
            <IonButton
                fill="outline"
                color="light"
                expand="full"
                className="ion-text-right text-muted"
                onClick={() => {
                  setFiltersvisible(false);
                }}
                style={{ marginTop: 8 }}
            >
              Filter anwenden
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    ) : (
      <></>
    );
  };

  const createContent = () => {
    return (
      <>
        {pois.length ? (
          <IonGrid>
            <IonRow className="jc-nopadding">
              {pois.map(poi => {
                return passFilter(poi);
              })}
            </IonRow>
          </IonGrid>
        ) : (
          <IonRow>
            <IonCol>{hinweis}</IonCol>
          </IonRow>
        )}
      </>
    );
  };

  return (
    <IonPage>
      <AppHeader />
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={e => {
            doRefresh(e, true);
          }}
        >
          <IonRefresherContent refreshingSpinner="dots" />
        </IonRefresher>
        <IonRow className="list-header">
          <IonCol>
            <h3>{listenname}</h3>{" "}
            {pois.length === 0 && hinweis === "" ? (
              <IonSpinner name="dots" color="medium" />
            ) : null}
            </IonCol>
          {match.params.type === "milestone" ? (
            hinweis === "" ? (
                <IonCol size="12" className="ion-align-self-center ion-text-right">
                  <IonButton routerLink={"/karte/milestone/" + match.params.uid}>
                    <IonIcon icon={locate} />
                    auf Karte
                  </IonButton>
                </IonCol>
            ) : null
          ) : (pois.length ?
                  <IonCol>
              <p className="ion-text-right">
                <IonButton
                  fill={filterActive ? "outline" : "clear"}
                  className="text-muted"
                  onClick={() => {
                    setFiltersvisible(true);
                  }}
                >
                  Filter <IonIcon icon={filter} />
                </IonButton>
              </p>
            </IonCol> : null
          )}
        </IonRow>
        {createContent()}
        <IonToast
            isOpen={toastVisible}
            onDidDismiss={() => setToastVisible(false) }
            message={toastText}
            duration={2000}
        />
      </IonContent>
      <IonModal
        isOpen={filtersvisible}
        onDidDismiss={() => setFiltersvisible(false)}
        cssClass="filter-overlay"
      >
        <IonToolbar>
          <IonTitle>Filter</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                setFiltersvisible(false);
              }}
            >
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent>{createFilter()}</IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Liste;
